$sep-heights:(
    5: 5px,
    10: 10px,
    20: 20px,
    30: 30px,
    50: 50px
);

@each $sep-height, $height in $sep-heights{
    .sep-#{$sep-height}{
        height: $height;
        clear: both;
    }
}
