#mobile-nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900 !important;
    background-color: white;
    visibility: hidden;
    opacity: 0;
    transition: $def-trans;
    overflow-y: auto;
    padding: $header-height;
    @media (max-width: $screen-xl-max){
        padding: $header-height-xl;
    }
    @media (max-width: $screen-lg-max){
        padding: $header-height-lg;
    }
    @media (max-width: $screen-md-max){
        padding: $header-height-md;
    }
    @media (max-width: $screen-sm-max){
        padding: $header-height-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: $header-height-xs;
    }
    &.open{
        visibility: visible;
        opacity: 1;
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%);
        }
    }
    .mobile-nav-holder{
        position: relative;
        background-color: $dark-green;
        border-radius: $border-radius;
        display: grid;
        grid-template-rows: minmax(min-content, auto) min-content; 
        grid-template-columns: 1fr;
        min-height: 100%;
        overflow: auto;
        padding: $header-biglogo-height calc(#{$page-padding-nr} - #{$header-height}) $header-height calc(#{$page-padding-nr} - #{$header-height});
        @media (max-width: $screen-xl-max){
            padding: $header-biglogo-height-xl calc(#{$page-padding-nr-xl} - #{$header-height-xl}) $header-height-xl calc(#{$page-padding-nr-xl} - #{$header-height-xl});
        }
        @media (max-width: $screen-lg-max){
            padding: $header-biglogo-height-lg calc(#{$page-padding-nr-lg} - #{$header-height-lg}) $header-height-lg calc(#{$page-padding-nr-lg} - #{$header-height-lg});
        }
        @media (max-width: $screen-md-max){
            padding: $header-biglogo-height-md calc(#{$page-padding-nr-md} - #{$header-height-md}) $header-height-md calc(#{$page-padding-nr-md} - #{$header-height-md});
        }
        @media (max-width: $screen-sm-max){
            padding: $header-biglogo-height-sm calc(#{$page-padding-nr-sm} - #{$header-height-sm}) $header-height-sm calc(#{$page-padding-nr-sm} - #{$header-height-sm});
        }
        @media (max-width: $screen-xs-max){
            padding: $header-biglogo-height-xs calc(#{$page-padding-nr-xs} - #{$header-height-xs}) $header-height-xs calc(#{$page-padding-nr-xs} - #{$header-height-xs});
        }
        .mobile-nav-main{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: $spacer*3;
            .mobile-nav-ul{
                padding: 0;
                margin: 0;
                list-style-type: none;
                li{
                    &.active{
                        a{
                            color: $main-green;
                            font-size: 3rem;
                        }
                    }
                    a{
                        position: relative;
                        display: inline-block;
                        font-family: $header-font;
                        font-weight: $header-font-regular;
                        color: $light-green;
                        text-decoration: none;
                        font-size: 2rem;
                        line-height: 1.5;
                        transform: translateX(0);
                        transition: $def-trans;
                        @media (max-width: $screen-sm-max){
                            font-size: 1.75rem;
                        }
                    }
                    &.active{
                        a{
                            cursor: default;
                        }
                    }
                    &:not(.active){
                        a:hover{
                            transform: translateX(5px);
                        }
                    }
                }
            }
        }
        .mobile-nav-info{
            ul#mobile-nav-add{
                padding: 0;
                margin: 0;
                list-style-type: none;
                li{
                    margin-bottom: $spacer*0.5;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        font-size: 1rem;
                        font-family: $header-font;
                        font-weight: $header-font-regular;
                        text-decoration: none;
                        color: $main-green;
                    }
                }
            }
            ul#mobile-nav-contact{
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: flex;
                li{
                    margin-right: $spacer*0.5;
                    display: flex;
                    align-items: center;
                    color: $light-green;
                    svg{
                        stroke: $light-green;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 1rem;
                        font-family: $header-font;
                        font-weight: $header-font-regular;
                        text-decoration: none;
                        color: $light-green;
                        display: flex;
                        align-items: center;
                        svg{
                            stroke: $light-green;
                        }
                    }
                }
            }
        }

        
        .nav-bg{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            overflow: hidden;
            border-radius: $border-radius;
            img{
                display: block;
                width: 50%;
                min-width: 500px;
                position: absolute;
                left: calc(50% - #{$spacer*3});
                bottom: 0;
            }
        }
    }
/*
    &.tiny{
        .mobile-nav__holder{
            top: 80px;
            height: calc(100% - 180px);
        }
    }
*/

}
