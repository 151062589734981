.map-holder{
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    background-color: gainsboro;
    border-radius: $border-radius;
    overflow: hidden;
    .map-placeholder{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/map_placeholder.jpg);
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        .map-consent{
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 2px 3px 1px rgba(0,0,0,0.2);
            width: 60%;
            max-width: 450px;
            padding: $spacer;
            text-align: center;
            .text{
                margin-bottom: $spacer;
                font-size: 0.9rem;
            }
            .button{
                display: inline-block;
                background-color: $main-green;
                padding: $spacer*0.5;
                color: rgba(255,255,255,0.9);
                margin-bottom: $spacer;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 0.73rem;
                border-radius: 5px;
                transition: $def-trans;
                &:hover{
                    transform: translateY(-3px);
                    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
                }
            }
            .description > *{
                font-size: 0.7rem;
                color: $gray-600;
                a{
                    color: $gray-600;
                    text-decoration: underline;
                }
            }
            .description{
                p{
                    margin-bottom: $spacer*0.25 !important;
                    &:last-child{
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
    #contact-map{
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
    }
}



#drop{
    width: 500px;
    height: 300px;
    background-color: beige;
}



#conatct-data{
    .contacts-list{
        padding: 0;
        margin: 0;
        list-style-type: none;
        padding-left: $spacer*2.5;
        li{
            position: relative;
            font-size: 1rem;
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            margin-bottom: $spacer;
            &:last-child{
                margin-bottom: 0;
            }
            .icon{
                width: 20px;
                height: 20px;
                position: absolute;
                left: -40px;
                top: 0;
                text-align: right;
            }
            p{
                margin: 0;
                font-size: 1.2rem;
            }
            a{
                font-weight: $main-font-regular;
                font-size: 1.2rem;
                &:hover{
                    font-weight: $main-font-regular;
                }
            }
        }
    }
}