@mixin add-responsive-flex-mixins($size, $min-width) {
    @media (min-width: $min-width) {

        .lf-col-#{$size}-1-3 {
            box-sizing: border-box !important;
            width: calc(100% / 3) !important;
            padding-left: $spacer;
            padding-right: $spacer;
            height: auto;
        }

        .lf-col-#{$size}-2-3 {
            box-sizing: border-box !important;
            width: calc(100% / 3 * 2) !important;
            padding-left: $spacer;
            padding-right: $spacer;
            height: auto;
        }

        .lf-col-#{$size}{
            box-sizing: border-box !important;
            flex: 0 1 0 !important;
            padding-left: $spacer;
            padding-right: $spacer;
            height: auto;
            &.df{
                display: flex !important;
            }
        }
        @for $i from 1 through 4 {
            $flex-basis: 25% * $i;
            .lf-col-#{$size}-#{$i} { 
                box-sizing: border-box !important;
                &.df{
                    display: flex !important;
                }
                //flex: 0 0 $flex-basis !important;
                width: $flex-basis !important;
                padding-left: $spacer;
                padding-right: $spacer;
            }
        }

        .lf-col-#{$size}-auto { 
            box-sizing: border-box !important;
            flex: 1 0 0 !important;
            &.df{
                display: flex !important;
            }
        }

        .fd-#{$size}-r{
            flex-direction: row !important;
        }
    
        .fd-#{$size}-c{
            flex-direction: column !important;
        }
    
        .jc-#{$size}-s{
            justify-content: flex-start !important;
        }
        .jc-#{$size}-c{
            justify-content: center !important;
        }
        .jc-#{$size}-e{
            justify-content: flex-end !important;
        }
        .jc-#{$size}-sbw{
            justify-content: space-between !important;
        }
        .jc-#{$size}-str{
            justify-content: stretch !important;
        }
    
        .ai-#{$size}-s{
            align-items: flex-start !important;
        }
        .ai-#{$size}-c{
            align-items: center !important;
        }
        .ai-#{$size}-e{
            align-items: flex-end !important;
        }
        .ai-#{$size}-str{
            align-items: stretch !important;
        }

    }
    
}

.lf-container,
.lf-container-nr,
.lf-container-fw{
    position: relative;
    padding: 0 $page-padding;
    margin: 0 auto;
    max-width: 2300px;
    @media (max-width: $screen-xl-max){
        padding: 0 $page-padding-xl;
    }
    @media (max-width: $screen-lg-max){
        padding: 0 $page-padding-lg;
    }
    @media (max-width: $screen-md-max){
        padding: 0 $page-padding-md;
    }
    @media (max-width: $screen-sm-max){
        padding: 0 $page-padding-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: 0 $page-padding-xs;
    }
    .lf-row{
        margin: 0 $spacer*-1;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
    }
    .lf-col{
        box-sizing: border-box !important;
        flex: 0 1 0 !important;
        padding-left: $spacer;
        padding-right: $spacer;
        height: auto;
        &.df{
            display: flex !important;
        }
    }
    @for $i from 1 through 4 {
        $flex-basis: 25% * $i;
        .lf-col-#{$i} { 
            box-sizing: border-box !important;
            &.df{
                display: flex !important;
            }
            //flex: 0 0 $flex-basis !important;
            width: $flex-basis !important;
            padding-left: $spacer;
            padding-right: $spacer;
        }
    }

    .lf-col-auto { 
        box-sizing: border-box !important;
        flex: 1 0 0 !important;
        padding-left: $spacer;
        padding-right: $spacer;
        height: auto;
    }

    .lf-col-1-3 {
        box-sizing: border-box !important;
        width: calc(100% / 3) !important;
        padding-left: $spacer;
        padding-right: $spacer;
        height: auto;
    }
    .lf-col-2-3 {
        box-sizing: border-box !important;
        width: calc(100% / 3  * 2) !important;
        padding-left: $spacer;
        padding-right: $spacer;
        height: auto;
    }

    
    @include add-responsive-flex-mixins("xs", $screen-xs-min);
    @include add-responsive-flex-mixins("sm", $screen-sm-min);
    @include add-responsive-flex-mixins("md", $screen-md-min);
    @include add-responsive-flex-mixins("lg", $screen-lg-min);
    @include add-responsive-flex-mixins("xl", $screen-xl-min);
    @include add-responsive-flex-mixins("xxl", $screen-xxl-min);
}

.lf-container{
    max-width: 2300px;
    margin: 0 auto;
}
.lf-container-nr{
    max-width: 2300px;
    padding: 0 $page-padding-nr !important;
    @media (max-width: $screen-xl-max){
        padding: 0 $page-padding-nr-xl !important;
    }
    @media (max-width: $screen-lg-max){
        padding: 0 $page-padding-nr-lg !important;
    }
    @media (max-width: $screen-md-max){
        padding: 0 $page-padding-nr-md !important;
    }
    @media (max-width: $screen-sm-max){
        padding: 0 $page-padding-nr-sm !important;
    }
    @media (max-width: $screen-xs-max){
        padding: 0 $page-padding-nr-xs !important;
    }
}

.lf-container-fw{
    padding: 0 !important;
    overflow: hidden;
}

.fd-r{
    flex-direction: row !important;
}

.fd-c{
    flex-direction: column !important;
}

.jc-s{
    justify-content: flex-start !important;
}
.jc-c{
    justify-content: center !important;
}
.jc-e{
    justify-content: flex-end !important;
}
.jc-sbw{
    justify-content: space-between !important;
}
.jc-str{
    justify-content: stretch !important;
}


.ai-s{
    align-items: flex-start !important;
}
.ai-c{
    align-items: center !important;
}
.ai-e{
    align-items: flex-end !important;
}
.ai-str{
    align-items: stretch !important;
}
