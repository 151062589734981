a,
a:hover,
a:focus,
a:visited {
    color: $text-color;
    font-weight: $main-font-bold;
}

.dl{
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-weight: $main-font-regular;
    transition: $def-trans;
    transform: translateY(0);
    border: none;
    background-color: none;
    background: none;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    &.bg{
        border: 2px solid $text-color;
        border-radius: 10px;
        padding: $spacer*0.5 $spacer;
        letter-spacing: 3px;
        padding-left: calc(#{$spacer} + 5px);
        background-color: rgba(0,0,0,0);
        &:hover{
            background-color: $text-color;
            color: rgba(255,255,255,0.9);
            transform: translateY(0);
            font-weight: $main-font-regular;
        }
        &.green{
            border-color: $main-green;
            color: $main-green;
            &:hover{
                background-color: $main-green;
                color: rgba(255,255,255,0.9);
            }
        }
        &.lightgreen{
            border-color: $light-green;
            color: $light-green;
            &:hover{
                background-color: $light-green;
                color: $dark-green;
            }
            
        }
    }
    &:hover{
        transform: translateY(-2px);
        font-weight: $main-font-bold;
    }
    &:focus{
        outline:0;
    }
    &.inactive{
        cursor: default;
        opacity: 0.5;
        &:hover{
            transform: translateY(0);
        }
    }
}