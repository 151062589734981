$menu-line-height: 5px;
$menu-icon-height: 18px;
$menu-icon-width: 50px;


$menu-icon-height-xs: 16px;
$menu-icon-width-xs: 42px;


.dark-nav:not(.nav-open){
    #menu-icon-wrapper{
        .title{
            color: $dark-green;
        }
    }
    .menu-icon-holder{
        .menu-icon{
            &:before,
            &:after{
                background-color: $dark-green;
            }
        }
    }
}

#menu-icon-wrapper{
    .title{
        display: block;
        text-align: center;
        color: white;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 4px;
        margin-right: -4px;
        margin-bottom: 7px;
        transition: $def-trans;
        opacity: 1;
        visibility: visible;
    }
}
.menu-icon-holder{
    display: flex;
    justify-content: center;
    position: relative;
    width: $menu-icon-width;
    height: $menu-icon-height;
    visibility: visible;
    opacity: 1;
    margin: 0 auto;
    transition: $def-trans;
    @media (max-width: $screen-xs-max){
        width: $menu-icon-width-xs;
        height: $menu-icon-height-xs;
    }
    &.tload{
        visibility: hidden;
        opacity: 0;
    }
    .menu-icon{
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        &:before,
        &:after{
            content: '';
            width: 100%;
            height: $menu-line-height;
            background-color: white;
            position: absolute;
            top: 0;
            left: 0;
            transition: $def-trans;
        }
        &:after{
            top: auto;
            bottom: 0;
        }
        &.open{
            &:before{
                transform: rotate(45deg) translateY(9px);
                @media (max-width: $screen-xs-max){
                    transform: rotate(45deg) translateY(8px);
                }
            }
            &:after{
                transform: rotate(-45deg) translateY(-9px);
                @media (max-width: $screen-xs-max){
                    transform: rotate(-45deg) translateY(-8px);
                }
            }
        }
    }
}



.dark{
    .menu-icon{
        span{
            background: white;
            &:before,
            &:after{
                background: white
            };
        }
    }
}