$gutterSize: $spacer*2;
$gutterSizeSmall: $spacer;

.team-grid{
    position: relative;
    width: 100%;
}

.gutter-sizer{
    width: $gutterSize;
    @media (max-width: $screen-sm-max){
        width: $gutterSizeSmall;
    }
}
.grid-sizer,
.grid-item{
    width: calc(25% - #{$gutterSize}*3/4);
    @media (max-width: $screen-md-max){
        width: calc(100%/3 - #{$gutterSize}*2/3);
    }
    @media (max-width: $screen-sm-max){
        width: calc(100%/3 - #{$gutterSizeSmall}*2/3);
    }
    @media (max-width: $screen-xs-max){
        width: calc(50% - #{$gutterSizeSmall}/2);
    }
    &.width2{
        width: calc(50% - #{$gutterSize}*3/4);
        @media (max-width: $screen-md-max){
            width: calc(100%/3*2 - #{$gutterSize}*2/3);
        }
        @media (max-width: $screen-sm-max){
            width: calc(100%/3*2 - #{$gutterSizeSmall}*2/3);
        }
        @media (max-width: $screen-xs-max){
            width: calc(100%);
        }
    }
}

.grid-item{
    margin-bottom: $gutterSize;
    @media (max-width: $screen-sm-max){
        margin-bottom: $gutterSize;
    }
}

.person{
    .image-holder{
        width: 100%;
        position: relative;
        margin-bottom: $spacer*0.5;
        &:after{
            content: '';
            display: block;
            padding-bottom: 100%;
        }
        img{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover !important;
            mask-image: url(../images/r_mask.svg);
        }
    }
    .data{
        padding: 0 $border-radius*1.5;
        .name{
            font-family: $header-font;
            font-size: 1.2rem;
            color: $light-green;
        }
        .position{
            font-family: $main-font;
            font-weight: $main-font-bold;
            font-size: 0.9333rem;
            color: $dark-green;
        }
    }
}


.team-image-container{
    .team-image{
        position: relative;
        margin-bottom: $spacer*0.75;
        @media (max-width: $screen-md-max){
            width: 100%;
        }
        &:after{
            content: '';
            display: block;
            padding-bottom: 57%;
        }
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            mask-image: url(../images/rq_mask.svg);
            mask-size: cover;
        }
    }
    .team-image-text{
        padding: 0 $border-radius*1.5;
        color: white;
        width: 100%;
    }
}