@mixin add-responsive-display-mixins($size, $min-width) {
    @media (min-width: $min-width) {
        .d-#{$size}-n{
            display: none !important;
        }
        .d-#{$size}-b{
            display: block !important;
        }
        .d-#{$size}-f{
            display: flex !important;
        }
        .d-#{$size}-if{
            display: inline-flex !important;
        }
    }
}

.d-n{
    display: none !important;
}
.d-b{
    display: block !important;
}
.d-f{
    display: flex !important;
}
.d-if{
    display: inline-flex !important;
}

@include add-responsive-display-mixins("xs", $screen-xs-min);
@include add-responsive-display-mixins("sm", $screen-sm-min);
@include add-responsive-display-mixins("md", $screen-md-min);
@include add-responsive-display-mixins("lg", $screen-lg-min);
@include add-responsive-display-mixins("xl", $screen-xl-min);
@include add-responsive-display-mixins("xxl", $screen-xxl-min);