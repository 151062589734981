.fw-side-image{
    width: 100%;
    position: relative;
    img{
        position: absolute;
        right: 0;
        top:50%;
        transform: translate($spacer*-1, -50%);
        width: calc(100% + #{$page-padding-nr} + 30px) !important;
        @media (max-width: $screen-xl-max){
            width: calc(100% + #{$page-padding-nr-xl} + 30px) !important;
        }
        @media (max-width: $screen-lg-max){
            width: calc(100% + #{$page-padding-nr-lg} + 30px) !important;
        }
        @media (max-width: $screen-md-max){
            width: calc(100% + #{$page-padding-nr-md} + 30px) !important;
        }
        @media (max-width: $screen-sm-max){
            position: relative;
            width: calc(100% + #{$page-padding-nr-sm} + 20px) !important;
            transform: translate(calc(#{$page-padding-nr-sm}*-1 - 20px), 0);
            top: auto;
            left: auto;
        }
        @media (max-width: $screen-xs-max){
            width: calc(100% + #{$page-padding-nr-xs} + 20px) !important;
            transform: translate(calc(#{$page-padding-nr-xs}*-1 - 20px), 0);
        }
    }
}