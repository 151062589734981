footer{
    .footer-title{
        display: inline-block;
        font-size: $h2FontSize;
        font-family: $header-font;
        color: $main-green;
        margin-bottom: $spacer*2;
    }
    .footer-list{
        padding: 0;
        margin: 0;
        list-style-type: none;
        li{
            margin-bottom: $spacer*0.75;
            &:last-child{
                margin-bottom: 0;
            }
            a{
                font-family: $main-font;
                font-weight: $main-font-regular;
                font-size: 1.15rem;

            }
        }
    }
    .copy{
        color: $gray-700;
        font-size: 0.8333rem;
    }
    .footer-social{
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        li{
            margin-right: $spacer;
            &:last-child{
                margin-right: 0;
            }
            a{
                font-size: 25px;
            }
        }
    }
    .footer-right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        @media (max-width: $screen-md-max){
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
        @media (max-width: $screen-xs-max){
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .footer-right-bottom{
            width: 50%;
            @media (max-width: $screen-md-max){
                width: 50%;
                padding-left: $spacer;
                padding-top: $spacer*0.5;
            }
            @media (max-width: $screen-xs-max){
                width: 100%;
                padding-left: 0;
            }
            svg{
                height: 65px;
                @media (min-width: $screen-lg-min){
                    height: 85px;
                }
                @media (min-width: $screen-xl-min){
                    height: 95px;
                }
                @media (min-width: $screen-xxxl-min){
                    height: 120px;
                }
            }
        }
    }

    #footer-bottom{
        position: relative;
        height: 50px;
        width: 100%;
        background-color: $dark-green;
        #footer-logo-holder{
            position: absolute;
            width: 450px;
            bottom: 49px;
            right: $page-padding-nr;
            @media (max-width: $screen-xl-max){
                right: $page-padding-nr-xl;
                width: 400px;
            }
            @media (max-width: $screen-lg-max){
                right: $page-padding-nr-lg;
                width: 350px;
            }
            @media (max-width: $screen-md-max){
                right: $page-padding-nr-md;
                width: 300px;
            }
            @media (max-width: $screen-sm-max){
                right: $page-padding-nr-sm;
                width: 250px;
            }
            @media (max-width: $screen-xs-max){
                right: $page-padding-nr-xs;
                width: 230px;
            }
            #footer-logo-bg{
                width: 100%;
                display: flex;
                align-items: flex-end;
                svg{
                    width: 100%;
                    transform: rotate(180deg);
                    path{
                        fill: $dark-green;
                    }
                }
            }
            #footer-logo{
                position: absolute;
                width: 50%;
                bottom: $spacer;
                left: 50%;
                transform: translateX(-57%);
                svg * {
                    fill: $light-green;
                }
            }
        }
    }
}