.color-block{
    width: 100%;
    padding: 0 $header-height;
    @media (max-width: $screen-xl-max){
        padding: 0 $header-height-xl;
    }
    @media (max-width: $screen-lg-max){
        padding: 0 $header-height-lg;
    }
    @media (max-width: $screen-md-max){
        padding: 0 $header-height-md;
    }
    @media (max-width: $screen-sm-max){
        padding: 0 $header-height-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: 0 $header-height-xs;
    }
    .color-block__wrapper{
        width: 100%;
        background-color: $main-green;
        padding: $spacer*5 calc(#{$page-padding-nr} - #{$header-height});
        border-radius: $border-radius;
        @media (max-width: $screen-xl-max){
            padding: $spacer*5 calc(#{$page-padding-nr-xl} - #{$header-height-xl});
        }
        @media (max-width: $screen-lg-max){
            padding: $spacer*5 calc(#{$page-padding-nr-lg} - #{$header-height-lg});
        }
        @media (max-width: $screen-md-max){
            padding: $spacer*4 calc(#{$page-padding-nr-md} - #{$header-height-md});
        }
        @media (max-width: $screen-sm-max){
            padding: $spacer*3 calc(#{$page-padding-nr-sm} - #{$header-height-sm});
        }
        @media (max-width: $screen-xs-max){
            padding: $spacer*2 calc(#{$page-padding-nr-xs} - #{$header-height-xs});
        }
        &.green{
            background-color: $main-green;
            h1,h2,h3{
                color: $light-green;
                .sub{
                    color: $dark-green;
                }
            }
            p{
                color: white !important;
            }
        }
        &.dark-green{
            background-color: $dark-green;
            h1,h2,h3{
                color: $light-green;
                .sub{
                    color: $main-green;
                }
            }
            p{
                color: white !important;
            }
        }
        .content{
            width: calc(100%/3*2);
            @media (max-width: $screen-md-max){
                width: 100%;
            }
        } 
        h2{
            margin-top: 0;
        }
    }
    .side-image{
        max-width: 100%;
        img{
            width: 100%;
            transform: translate(calc(#{$page-padding-nr} - #{$header-height*2}), #{$spacer*5});
            @media (max-width: $screen-xl-max){
                transform: translate(calc(#{$page-padding-nr-xl} - #{$header-height-xl*2}), #{$spacer*5});
            }
            @media (max-width: $screen-lg-max){
                transform: translate(calc(#{$page-padding-nr-lg} - #{$header-height-lg*2}), #{$spacer*5});
            }
            @media (max-width: $screen-md-max){
                transform: translate(calc(#{$page-padding-nr-md} - #{$header-height-md*2}), #{$spacer*4});
            }
            @media (max-width: $screen-sm-max){
                transform: translate(calc(#{$page-padding-nr-sm} - #{$header-height-sm*2}), #{$spacer*3});
            }
            @media (max-width: $screen-xs-max){
                transform: translate(calc(#{$page-padding-nr-xs} - #{$header-height-xs*2}), #{$spacer*2});
            }
        }
    }
}

