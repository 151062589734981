header{
    position: relative;
    width: 100%;
    background-color: white;
    z-index: 10000;
    transition: $def-trans;
    &.nav-open{
        #header-bottom{
            #nav-icon-holder{
                .title{
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    &.sticky-top{
    }
    &.gray{
        #header-top{
            background-color: rgba(241,241,235);
        }
        #header-bottom-container{
            svg{
                path{
                    fill: rgba(241,241,235) !important;
                }
            }
        }
    }

    &.sticky-bottom{
        position: sticky;
        top: $header-height * -1;
        @media (max-width: $screen-xl-max){
            top: $header-height-xl * -1;
        }
        @media (max-width: $screen-lg-max){
            top: $header-height-lg * -1;
        }
        @media (max-width: $screen-md-max){
            top: $header-height-md * -1;
        }
        @media (max-width: $screen-sm-max){
            top: $header-height-sm * -1;
        }
        @media (max-width: $screen-xs-max){
            top: $header-height-xs * -1;
        }
        &.nav-open{
            #header-bottom{
                top: $header-height*2;
                @media (max-width: $screen-xl-max){
                    top: $header-height-xl*2;
                }
                @media (max-width: $screen-lg-max){
                    top: $header-height-lg*2;
                }
                @media (max-width: $screen-md-max){
                    top: $header-height-md*2;
                }
                @media (max-width: $screen-sm-max){
                    top: $header-height-sm*2;
                }
                @media (max-width: $screen-xs-max){
                    top: $header-height-xs*2;
                }
                #nav-icon-holder{
                    .title{
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
        #header-top{
        }
        &:not(.nav-open){
            #header-top{
                //top: $header-height;
                height: $header-height-sticky;
                background-color: $light-green;
                background-color: $main-green;
                box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
                @media (max-width: $screen-xl-max){
                    height: $header-height-sticky-xl;
                }
                @media (max-width: $screen-lg-max){
                    height: $header-height-sticky-lg;
                }
                @media (max-width: $screen-md-max){
                    height: $header-height-sticky-md;
                }
                @media (max-width: $screen-sm-max){
                    height: $header-height-sticky-sm;
                }
                @media (max-width: $screen-xs-max){
                    height: $header-height-sticky-xs;
                }
                
                #header-contact{
                    li{
                        color: $dark-green;
                        color: $light-green;
                        color: rgba(255,255,255,0.8);
                        svg{
                            stroke: $dark-green;
                            stroke: rgba(255,255,255,0.8);
                        }
                        a{
                            color: $dark-green;
                            stroke: rgba(255,255,255,0.8);
                        }
                    }
                }
            }
        }
        &.nav-open{
            #header-bottom{
                top: $header-height*2;
                @media (max-width: $screen-xl-max){
                    top: $header-height-xl*2;
                }
                @media (max-width: $screen-lg-max){
                    top: $header-height-lg*2;
                }
                @media (max-width: $screen-md-max){
                    top: $header-height-md*2;
                }
                @media (max-width: $screen-sm-max){
                    top: $header-height-sm*2;
                }
                @media (max-width: $screen-xs-max){
                    top: $header-height-xs*2;
                }
                #nav-icon-holder{
                    .title{
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
        &:not(.nav-open){
            #header-bottom{
                top: $header-height;
                height: $header-biglogo-height-tiny;
                @media (max-width: $screen-xl-max){
                    top: $header-height-xl;
                    height: $header-biglogo-height-tiny-xl;
                }
                @media (max-width: $screen-lg-max){
                    top: $header-height-lg;
                    height: $header-biglogo-height-tiny-lg;
                }
                @media (max-width: $screen-md-max){
                    top: $header-height-md;
                    height: $header-biglogo-height-tiny-md;
                }
                @media (max-width: $screen-sm-max){
                    top: $header-height-sm;
                    height: $header-biglogo-height-tiny-sm;
                }
                @media (max-width: $screen-xs-max){
                    top: $header-height-xs;
                    height: $header-biglogo-height-tiny-xs;
                }
                #nav-icon-holder{
                    height: $header-height-sticky;
                    @media (max-width: $screen-xl-max){
                        height: $header-height-sticky-xl;
                    }
                    @media (max-width: $screen-lg-max){
                        height: $header-height-sticky-lg;
                    }
                    @media (max-width: $screen-md-max){
                        height: $header-height-sticky-md;
                    }
                    @media (max-width: $screen-sm-max){
                        height: $header-height-sticky-sm;
                    }
                    @media (max-width: $screen-xs-max){
                        height: $header-height-sticky-xs;
                    }
                    .title{
                        transform: translateY(-40px);
                        height: 0;
                        margin-bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        overflow: hidden;
                    }
                    .menu-icon-holder{
                        .menu-icon{
                            &:before,
                            &:after{
                                background-color: $dark-green !important;
                            }
                        }
                    }
                }
                #logo-holder{
                    left: 50px !important;
                    right: auto !important;
                    height: $header-biglogo-height-tiny !important;
                    @media (max-width: $screen-xl-max){
                        height: $header-biglogo-height-tiny-xl !important;
                    }
                    @media (max-width: $screen-lg-max){
                        height: $header-biglogo-height-tiny-lg !important;
                    }
                    @media (max-width: $screen-md-max){
                        height: $header-biglogo-height-tiny-md !important;
                    }
                    @media (max-width: $screen-sm-max){
                        height: $header-biglogo-height-tiny-sm !important;
                    }
                    @media (max-width: $screen-xs-max){
                        height: $header-biglogo-height-tiny-xs !important;
                    }

                    
                
                    &:before{
                        background-color: $light-green !important;
                    }
                    svg{
                        filter: drop-shadow(0px -5px 5px rgb(0 0 0 / 0.4));
                    }
                    #logo-link{
                        width: 55%;
                        transform: translate(7%, -35%);
                    }
                }
            }
        }
    }
    #header-top{
        //position: absolute;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        background: rgba(255,255,255,1);
        height: $header-height;
        transition: $def-trans;
        background-color: white;
        @media (max-width: $screen-xl-max){
            height: $header-height-xl;
        }
        @media (max-width: $screen-lg-max){
            height: $header-height-lg;
        }
        @media (max-width: $screen-md-max){
            height: $header-height-md;
        }
        @media (max-width: $screen-sm-max){
            height: $header-height-sm;
        }
        @media (max-width: $screen-xs-max){
            height: $header-height-xs;
        }
        #header-contact{
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            li{
                margin-right: $spacer*0.75;
                color: $dark-green;
                display: flex;
                align-items: center;
                font-size: 0.925rem;
                line-height: 1;
                svg{
                    stroke: $dark-green;
                    stroke-width: 2px;
                }
                a{
                    display: block;
                    display: flex;
                    align-items: center;
                    color: $dark-green;
                }
            }
        }

       
    }
    #header-bottom{
        position: absolute;
        width: 100%;
        top: $header-height;
        left: 0;
        height: $header-biglogo-height;
        top: $header-height;
        @media (max-width: $screen-xl-max){
            top: $header-height-xl;
            height: $header-biglogo-height-xl;
            top: $header-height-xl;
        }
        @media (max-width: $screen-lg-max){
            top: $header-height-lg;
            height: $header-biglogo-height-lg;
            top: $header-height-lg;
        }
        @media (max-width: $screen-md-max){
            top: $header-height-md;
            height: $header-biglogo-height-md;
            top: $header-height-md;
        }
        @media (max-width: $screen-sm-max){
            top: $header-height-sm;
            height: $header-biglogo-height-sm;
            top: $header-height-sm;
        }
        @media (max-width: $screen-xs-max){
            top: $header-height-xs;
            height: $header-biglogo-height-xs;
            top: $header-height-xs;
        }
        #nav-icon-holder{
            position: absolute;
            left: 0;
            top: 0;
            height: $header-biglogo-height;
            display: flex;
            align-items: center;
            z-index: 10;
            transition: $def-trans;
            @media (max-width: $screen-xl-max){
                height: $header-biglogo-height-xl;
            }
            @media (max-width: $screen-lg-max){
                height: $header-biglogo-height-lg;
            }
            @media (max-width: $screen-md-max){
                height: $header-biglogo-height-md;
            }
            @media (max-width: $screen-sm-max){
                height: $header-biglogo-height-sm;
            }
            @media (max-width: $screen-xs-max){
                height: $header-biglogo-height-xs;
            }
        }
        #header-bottom-container{
            position: relative;
            width: 100%;
            height: 100%;
            #logo-holder{
                position: absolute;
                left: 0;
                top:-1px;
                height: $header-biglogo-height;
                z-index: 1;
                transition: $def-trans;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: $screen-xl-max){
                    height: $header-biglogo-height-xl;
                }
                @media (max-width: $screen-lg-max){
                    height: $header-biglogo-height-lg;
                }
                @media (max-width: $screen-md-max){
                    height: $header-biglogo-height-md;
                }
                @media (max-width: $screen-sm-max){
                    height: $header-biglogo-height-sm;
                    left: auto;
                    right: 0;
                }
                @media (max-width: $screen-xs-max){
                    height: $header-biglogo-height-xs;
                    left: auto;
                    right: 0;
                }
                @media (orientation: landscape) and (max-width: $screen-xl-max) and (max-height: 560px){
                    height: $header-biglogo-height-xs;
                }
                svg{
                    height: 100%;
                    path{
                        fill: white;
                    }
                }
                #logo-link{
                    position: absolute;
                    width: 45%;
                    max-width: 50%;
                    max-height: 50%;
                    transform: translate(9%, -6%);
                    img{
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}
