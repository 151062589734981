$slide-width: 0.8;
$slide-width-xl: 0.85;
$slide-width-lg: 0.875;
$slide-width-md: 0.9;
$slide-width-sm: 0.95;
$slide-width-xs: 1;


#hgm-home-slider{
    position: relative;
    width: 100%;
    height: calc(100vh - #{$header-height});
    background-color: white;
    @media (max-width: $screen-xl-max){
        height: calc(100vh - #{$header-height-xl});
    }
    @media (max-width: $screen-lg-max){
        height: calc(100vh - #{$header-height-lg});
    }
    @media (max-width: $screen-md-max){
        height: calc(100vh - #{$header-height-md});
    }
    @media (max-width: $screen-sm-max){
        height: calc(100vh - #{$header-height-sm});
    }
    @media (max-width: $screen-xs-max){
        height: calc(100vh - #{$header-height-xs});
    }
    #hgm-home-slider-slides{
        width: 100%;
        height: calc(100% - #{$header-height});
        @media (max-width: $screen-xl-max){
            height: calc(100% - #{$header-height-xl});
        }
        @media (max-width: $screen-lg-max){
            height: calc(100% - #{$header-height-lg});
        }
        @media (max-width: $screen-md-max){
            height: calc(100% - #{$header-height-md});
        }
        @media (max-width: $screen-sm-max){
            height: calc(100% - #{$header-height-sm});
        }
        @media (max-width: $screen-xs-max){
            height: calc(100% - #{$header-height-xs});
        }
        .slide{
            position: relative;
            height: calc(100vh - #{$header-height}*2) !important;
            width: calc((100vw - #{$header-height}*2) * #{$slide-width}) !important;
            border-radius: $border-radius;
            overflow: hidden;
            margin-left: $header-height;
            @media (max-width: $screen-xl-max){
                height: calc(100vh - #{$header-height-xl}*2) !important;
                width: calc((100vw - #{$header-height-xl}*2) * #{$slide-width-xl}) !important;
                margin-left: $header-height-xl;
            }
            @media (max-width: $screen-lg-max){
                height: calc(100vh - #{$header-height-lg}*2) !important;
                width: calc((100vw - #{$header-height-lg}*2) * #{$slide-width-lg}) !important;
                margin-left: $header-height-lg;
            }
            @media (max-width: $screen-md-max){
                height: calc(100vh - #{$header-height-md}*2) !important;
                width: calc((100vw - #{$header-height-md}*2) * #{$slide-width-md}) !important;
                margin-left: $header-height-md;
            }
            @media (max-width: $screen-sm-max){
                height: calc(100vh - #{$header-height-sm}*2) !important;
                width: calc((100vw - #{$header-height-sm}*2) * #{$slide-width-sm}) !important;
                margin-left: $header-height-sm;
            }
            @media (max-width: $screen-xs-max){
                height: calc(100vh - #{$header-height-xs}*2) !important;
                width: calc((100vw - #{$header-height-xs}*2) * #{$slide-width-xs}) !important;
                margin-left: $header-height-xs;
            }
            .slide__image{
                width: 100%;
                height: 100%;
                position: absolute;
                left:0;
                top: 0;
                .overlay{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: linear-gradient(180deg, rgba(0,0,0,0.10407913165266103) 0%, rgba(0,0,0,0.4990371148459384) 50%, rgba(0,0,0,0.896796218487395) 100%);
                    &.o-25{
                        opacity: 0.25;
                    }
                    &.o-50{
                        opacity: 0.5;
                    }
                    &.o-75{
                        opacity: 0.75;
                    }
                    &.o-100{
                        opacity: 1;
                    }
                }
                img{
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .slide__content{
                position: absolute;
                left: calc(#{$page-padding-nr} - #{$header-height});
                bottom: $header-height;
                transition: $def-trans;
                @media (max-width: $screen-xl-max){
                    left: calc(#{$page-padding-nr-xl} - #{$header-height-xl});
                    bottom: $header-height-xl;
                }
                @media (max-width: $screen-lg-max){
                    left: calc(#{$page-padding-nr-lg} - #{$header-height-lg});
                    bottom: $header-height-lg;
                }
                @media (max-width: $screen-md-max){
                    left: calc(#{$page-padding-nr-md} - #{$header-height-md});
                    bottom: $header-height-md;
                }
                @media (max-width: $screen-sm-max){
                    left: calc(#{$page-padding-nr-sm} - #{$header-height-sm});
                    bottom: $header-height-sm;
                }
                @media (max-width: $screen-xs-max){
                    left: calc(#{$page-padding-nr-xs} - #{$header-height-xs});
                    bottom: $header-height-xs;
                }
                .slide__content-header{
                    font-size: $h1FontSize;
                    color: white;
                    line-height: 1;
                    font-family: $header-font;
                    margin-bottom: $spacer*2;
                    .slide__content-subheader{
                        color: $main-green;
                    }
                }
                .slide__content-description{
                    max-width: 50%;
                    color: white;
                    line-height: 1.85;
                    font-size: 0.95rem;
                    @media (orientation: landscape) and (max-width: $screen-xl-max) and (max-height: 560px){
                        max-width: 100%;
                        width: 100%;
                    }
                    @media (orientation: landscape) and (max-width: $screen-sm-max) and (max-height: 560px){
                        display: none;
                    }
                }
                .slide__content-link{
                    margin-top: $spacer*2;
                }
            }
        }
    }

    .slick-nav-right{
        position: absolute;
        bottom: $header-height*2;
        z-index: 100;
        display: block;
        left: calc((100vw - (#{$header-height}*2)) * #{$slide-width} + 100px + #{$header-height}) !important;
        width: 100px;
        height: 100px;
        transition: $def-trans;
        @media (max-width: $screen-xl-max){
            bottom: $header-height-xl*2;
            left: calc((100vw - (#{$header-height-xl}*2)) * #{$slide-width-xl} + 100px + #{$header-height-xl}) !important;
        }
        @media (max-width: $screen-lg-max){
            bottom: $header-height-lg*2;
            left: calc((100vw - (#{$header-height-lg}*2)) * #{$slide-width-lg} + 100px + #{$header-height-lg}) !important;
        }
        @media (max-width: $screen-md-max){
            bottom: $header-height-md*2;
            left: calc((100vw - (#{$header-height-md}*2)) * #{$slide-width-md} + 100px + #{$header-height-md}) !important;
        }
        @media (max-width: $screen-sm-max){
            bottom: $header-height-sm*2;
            left: calc((100vw - (#{$header-height-sm}*2)) * #{$slide-width-sm} + 100px + #{$header-height-sm}) !important;
        }
        @media (max-width: $screen-xs-max){
            bottom: $header-height-xs*2;
            left: calc((100vw - (#{$header-height-xs}*2)) * #{$slide-width-xs} + 100px + #{$header-height-xs}) !important;
        }
        svg{
            stroke-width: 1px;
            stroke: $gray-100;
            opacity: 0.5;
            transition: $def-trans;
        }
        &:hover{
            transform: translateX(5px);
            svg{
                opacity: 0.9;
            }
        }
    }
}

.slick-dots{
    height: $header-height;
    bottom: $header-height*-1 !important;
    text-align: left !important;
    display: flex !important;
    align-items: center !important;
    padding-left: calc(#{$page-padding-nr}) !important;
    @media (max-width: $screen-xl-max){
        height: $header-height-xl;
        bottom: $header-height-xl*-1 !important;
        padding-left: calc(#{$page-padding-nr-xl}) !important;
    }
    @media (max-width: $screen-lg-max){
        height: $header-height-lg;
        bottom: $header-height-lg*-1 !important;
        padding-left: calc(#{$page-padding-nr-lg}) !important;
    }
    @media (max-width: $screen-md-max){
        height: $header-height-md;
        bottom: $header-height-md*-1 !important;
        padding-left: calc(#{$page-padding-nr-md}) !important;
    }
    @media (max-width: $screen-sm-max){
        height: $header-height-sm;
        bottom: $header-height-sm*-1 !important;
        padding-left: calc(#{$page-padding-nr-sm}) !important;
    }
    @media (max-width: $screen-xs-max){
        height: $header-height-xs;
        bottom: $header-height-xs*-1 !important;
        padding-left: calc(#{$page-padding-nr-xs}) !important;
    }
    li{
        margin-left: 0 !important;
        margin-right: $spacer !important;
        button{
            &:before{
                content: '' !important;
                width: 6px !important;
                height: 6px !important;
                background-color: $dark-green;
                border-radius: 10px;
                top: 50% !important;
                left: 2px !important;
                transform: translateY(-3px);
                transition: $def-trans;
            }
        }
        &.slick-active{
            button{
                &:before{
                    width: 10px !important;
                    height: 10px !important;
                    transform: translateY(-5px);
                    left: 0 !important;
                    background-color: $main-green !important;
                    opacity: 1 !important;
                }
            }
        }
    }
}

.slick-slide{
    .slide__content{
        visibility: hidden;
        opacity: 0;
    }
    &.slick-active{
        .slide__content{
            visibility: visible;
            opacity: 1;
        }
    }
}