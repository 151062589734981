header{
    .languages-holder{
        overflow: visible;
        display: flex;
        margin-left: $spacer;
        svg{
            margin-right: $spacer*0.25;
        }
        .languages{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            li{
                margin-right: $spacer*0.5;
                display: flex;
                align-items: center;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    display: flex;
                    font-weight: 500;
                    text-decoration: none;
                    text-transform: capitalize;
                }
                &.active{
                    a{
                        cursor: default;
                    }
                }
                &.active{
                    a{
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
