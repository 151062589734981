$body-font-size: 18px;
html{
    font-size: $body-font-size;
    @media (min-width: $screen-xxxl-min){
        font-size: $body-font-size*1.5;
    }
    @media (max-width: $screen-xl-max){
        font-size: $body-font-size*0.975;
    }
    @media (max-width: $screen-lg-max){
        font-size: $body-font-size*0.95;
    }
    @media (max-width: $screen-md-max){
        font-size: $body-font-size*0.925;
    }
    @media (max-width: $screen-sm-max){
        font-size: $body-font-size*0.9;
    }
    @media (max-width: $screen-xs-max){
        font-size: $body-font-size*0.85;
    }
}

$h1FontSize: 2.688rem;
$h2FontSize: 2.074rem;
$h3FontSize: 1.728rem;
$h4FontSize: 1.44rem;
$h5FontSize: 1.22rem;
$smallTextFontSize: 0.833rem;
$tinyTextFontSize: 0.694rem;

.heading{
    font-family: $header-font;
    font-weight: $header-font-bold;
    line-height: 1;
    margin: 3rem 0 1.55rem;
    display: block;
    .sub{
        color: $main-green;
        font-size: 2rem;
    }
    @media (max-width: $screen-md-max){
        margin: 2.75rem 0 1.3rem;
        line-height: 1.375;
    }
    @media (max-width: $screen-sm-max){
        margin: 2.5rem 0 1.25rem;
        line-height: 1.3;
    }
    @media (max-width: $screen-xs-max){
        margin: 2rem 0 1.2rem;
        line-height: 1.25;
    }
}

h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5{
    @extend .heading;
    &.big{
        font-size: $h1FontSize !important;
    }
    &.green{
        color: $main-green;
    }
}

h1,.h1{
    margin-top: 0;
    font-size: $h1FontSize;
    
}

h2,.h2{
    font-size: $h2FontSize;
}

h3,.h3{
    font-size: $h3FontSize;
}

h4,.h4{
    font-size: $h4FontSize;
}

h5,.h5{
    font-size: $h5FontSize;
}

p{
    font-size: 0.95rem;
    line-height: 1.85;
    font-family: $main-font;
    font-weight: $main-font-regular;
    margin-bottom: 1rem;
    margin-top: 0;
    &:last-child{
        margin-bottom: 0;
    }
    strong{
        font-weight: bold;
    }
}

.uc{
    text-transform: uppercase !important;
}

.hc{
    column-count: 2;
    column-gap: $spacer*3;
    @media (max-width: $screen-md-max){
        column-count: 1;
    }
    @media (max-width: $screen-xs-max){
    }
    p{
        margin-top: 0;
        &:last-child{
            margin-bottom: 0;
        }
    }
}