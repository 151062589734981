.section{
    margin-bottom: $spacer*10;
    @media (max-width: $screen-xl-max){
        margin-bottom: $spacer*8;
    }
    @media (max-width: $screen-lg-max){
        margin-bottom: $spacer*7;
    }
    @media (max-width: $screen-md-max){
        margin-bottom: $spacer*6;
    }
    @media (max-width: $screen-sm-max){
        margin-bottom: $spacer*5;
    }
    @media (max-width: $screen-xs-max){
        margin-bottom: $spacer*4;
    }
    h1,h2,h3{
        margin-top: 0;
    }
    .section-rtl{
        flex-direction: row-reverse;
        .section-columns-image {
            &.stretch{
                transform: translateX($page-padding-nr*-1);
                img{
                    border-radius: 0 $border-radius $border-radius 0;
                }
                @media (max-width: $screen-xl-max){
                    transform: translateX($page-padding-nr-xl*-1);
                }
                @media (max-width: $screen-lg-max){
                    transform: translateX($page-padding-nr-lg*-1);
                }
                @media (max-width: $screen-md-max){
                    transform: translateX($page-padding-nr-md*-1);
                }
                @media (max-width: $screen-sm-max){
                    transform: translateX($page-padding-nr-sm*-1);
                }
                @media (max-width: $screen-xs-max){
                    transform: translateX($page-padding-nr-xs*-1);
                }
            }
        }


        .mobile-image{
            &.stretch{
                img{
                    border-radius: $border-radius 0 0 $border-radius;
                    width: 100%;
                    object-fit: cover;
                    @media (max-width: $screen-xs-max){
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
    h3{
        margin-top: 0;
    }
    .mobile-image{
        position: relative;
        width: 100%;
        img{
            width: 100%;
            object-fit: cover;
            border-radius: $border-radius;
        }
        &.stretch{
            width: calc(100% + #{$page-padding-nr}*2) !important;
            transform: translateX($page-padding-nr*-1);
            img{
                border-radius: 0 $border-radius $border-radius 0;
                @media (max-width: $screen-xs-max){
                    border-radius: 0 !important;
                }
            }
            @media (max-width: $screen-xl-max){
                width: calc(100% + #{$page-padding-nr-xl}*2) !important;
                transform: translateX($page-padding-nr-xl*-1);
            }
            @media (max-width: $screen-lg-max){
                width: calc(100% + #{$page-padding-nr-lg}*2) !important;
                transform: translateX($page-padding-nr-lg*-1);
            }
            @media (max-width: $screen-md-max){
                width: calc(100% + #{$page-padding-nr-md}*2) !important;
                transform: translateX($page-padding-nr-md*-1);
            }
            @media (max-width: $screen-sm-max){
                width: calc(100% + #{$page-padding-nr-sm}*2) !important;
                transform: translateX($page-padding-nr-sm*-1);
            }
            @media (max-width: $screen-xs-max){
                width: calc(100% + #{$page-padding-nr-xs}*2) !important;
                transform: translateX($page-padding-nr-xs*-1);
            }
        }
    }
    .section-image{
        position: relative;
        width: 100%;
        img{
            border-radius: $border-radius;
            width: 100%;
            object-fit: cover;
        }
    }
    .section-columns-image {
        position: relative;
        width: 100%;
        img{
            border-radius: $border-radius;
            width: 100%;
            object-fit: cover;
        }
        &.stretch{
            width: calc(100% + #{$page-padding-nr}) !important;
            img{
                border-radius: $border-radius 0 0 $border-radius;
            }
            @media (max-width: $screen-xl-max){
                width: calc(100% + #{$page-padding-nr-xl}) !important;
            }
            @media (max-width: $screen-lg-max){
                width:  calc(100% + #{$page-padding-lg}) !important;
            }
            @media (max-width: $screen-md-max){
                width:  calc(100% + #{$page-padding-md}) !important;
            }
            @media (max-width: $screen-sm-max){
                width:   calc(100% + #{$page-padding-sm}) !important;
            }
            @media (max-width: $screen-xs-max){
                width: calc(100% + #{$page-padding-xs}) !important;
            }
        }
    }
}