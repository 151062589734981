.projects,
.items{
    .projects__holder,
    .home-projects__holder,
    .home-items__holder {
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        &.vis{
            visibility: visible;
            opacity: 1;
        }
        .gutter{
            width: $spacer*2;
        }
        &.nm{
            display: flex;
            flex-wrap: wrap;
            .item{
                position: relative;
                margin-right: $spacer*2;
                width: calc(100% / 3 - #{$spacer} * 2 * 2 / 3);
                &:nth-child(3n){
                    margin-right: 0;
                }
                
                @media (max-width: $screen-md-max){
                    width: calc(50% - #{$spacer*2}/2);
                    &:nth-child(3n){
                        margin-right: $spacer*2;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                @media (max-width: $screen-sm-max){
                    width: 100%;
                    margin-right: 0;
                }
                &:after{
                    content: '';
                    display: block;
                    padding-bottom: 72%;
                }
                .img-holder{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .item{
            display: block;
            transition: all 200ms ease-out;
            overflow: hidden;
            margin-bottom: $spacer*2;
            width: calc(33.3% - #{$spacer} * 4 / 3);
            @media (max-width: $screen-md-max){
                width: calc(50% - #{$spacer});
            }
            @media (max-width: $screen-sm-max){
                width: 100%;
            }
            &:hover {
                .img-holder{
                    img{
                        filter: blur(0px);
                        transform: scale(1);
                    }
                }
            }
            .img-holder{
                position: relative;
                width: 100%;
                overflow: hidden;
                img {
                    width: 100%;
                    transform: scale(1.05);
                    filter: blur(0.25px);
                    transition: all 200ms ease-out;
                }
                .overlay{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.1+0,0.1+33,0.7+66,0.8+100 */
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.1+0,0.1+33,0.6+66,0.7+100 */
                    background: -moz-linear-gradient(top,  rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 33%, rgba(0,0,0,0.6) 66%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 33%,rgba(0,0,0,0.6) 66%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 33%,rgba(0,0,0,0.6) 66%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#b3000000',GradientType=0 ); /* IE6-9 */
                    overflow: hidden;
                    opacity: 1;
                    visibility: visible;
                    transition: $def-trans;
                    .content{
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        .title{
                            color: white;
                            font-family: $header-font;
                            font-weight: $header-font-regular;
                            font-size: 2rem;
                            line-height: 1;
                            padding: $spacer;
                            max-width: 80%;
                            @media (max-width: $screen-lg-max){
                                font-size: 1.5rem;
                            }
                            @media (max-width: $screen-sm-max){
                                font-size: 1.3rem;
                            }
                        }
                        .category{
                            font-family: $header-font;
                            font-weight: $header-font-bold;
                            color: white;
                            text-transform: uppercase;
                            font-size: 0.833rem;
                            padding: $spacer;
                            position: relative;
                            &:before{
                                content: '';
                                position: absolute;
                                width: 70%;
                                height: 1px;
                                background-color: rgba(255,255,255,0.7);
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                    
                }
            }
        }
    }


    
    .link-holder{
        .link{
            width: 100%;
            display: flex;
            justify-content: center;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F5F5F5;
                width: calc(33.3% - #{$spacer} * 4 / 3);
                padding: $spacer*3;
                text-decoration: none;
                font-family: $header-font;
                font-weight: 900;
                font-size: 1.75rem;
                line-height: 24px;
                transition: $def-trans;
                @media (max-width: $screen-md-max){
                    width: calc(50% - #{$spacer});
                }
                @media (max-width: $screen-sm-max){
                    width: 100%;
                }
                svg{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    transform: translateY(2px);
                }
                &:hover{
                    transform: translateY(-3px);
                    box-shadow: 0px 7px 15px -12px rgba(0,0,0,0.3);
                }
            }
        }
    }
    .home-projects__wrapper{
        padding-left: $page-padding-nr;
        display: flex;
        align-items: stretch;
        position: relative;
        .nav{
            position: absolute;
            left: calc(#{$page-padding-nr} - 40px - #{$spacer});
            top: 50%;
            transform: translate(0, -50%);
            display: block;
            width: 40px;
            height: 40px;
            transition: $def-trans;
            &:hover{
                transform: translate(-3px, -50%);
                svg{
                    stroke: $main-green
                }
            }
            i,svg{
                width: 40px;
                height: 40px;
                transition: $def-trans;
            }
        }
        @media (max-width: $screen-xl-max){
            padding-left: $page-padding-nr-xl;
            .nav{
                left: calc(#{$page-padding-nr-xl} - 40px - #{$spacer});
            }
        }
        @media (max-width: $screen-lg-max){
            padding-left: $page-padding-nr-lg;
            .nav{
                left: calc(#{$page-padding-nr-lg} - 40px - #{$spacer});
            }
        }
        @media (max-width: $screen-md-max){
            padding-left: $page-padding-nr-md;
            .nav{
                left: calc(#{$page-padding-nr-md} - 40px - #{$spacer});
            }
        }
        @media (max-width: $screen-sm-max){
            padding-left: $page-padding-nr-sm;
            .nav{
                display: none;
            }
        }
        @media (max-width: $screen-xs-max){
            padding-left: $page-padding-nr-xs;
            .nav{
                display: none;
            }
        }
        .slick-slide{
            margin-right: $spacer;
            margin-bottom: 0;
            @media (max-width: $screen-sm-max){
                margin-right: $spacer*0.5;
            }
        }
        .item{
            margin-bottom: 0;
            width: 400px !important;
            position: relative;
            @media (max-width: $screen-xl-max){
            }
            @media (max-width: $screen-lg-max){
            }
            @media (max-width: $screen-md-max){
                width: 350px !important;
            }
            @media (max-width: $screen-sm-max){
                width: 320px !important;
            }
            @media (max-width: $screen-xs-max){
            }
        }
    }
}

#cats_container{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li{
        margin-right: $spacer;
        @media (max-width: $screen-xs-max){
            margin-right: $spacer;
        }
        a{
            display: inline-block;
            font-family: $header-font;
            font-weight: $header-font-bold;
            font-size: 0.8333rem;
            color: $gray-600;
            transition: $def-trans;
            background-color: $gray-200;
            padding: $spacer*0.5 $spacer;
            margin-bottom: $spacer*0.5;
            border-radius: 6px;
            text-transform: uppercase;
            text-decoration: none;
            @media (max-width: $screen-md-max){
                padding: $spacer*0.5 $spacer*1;
            }
            @media (max-width: $screen-xs-max){
                padding: $spacer*0.25 $spacer*0.5;
            }
            &:hover{
                color: $text-color;
            }
        }
        &.active{
            a{
                color: white;
                background-color: $main-green;
            }
        }
    }
}