#references-snippet{
    position: relative;
    width: 100%;
    padding: 0 $page-padding-nr;
    @media (max-width: $screen-xl-max){
        padding: 0 $page-padding-nr-xl;
    }
    @media (max-width: $screen-lg-max){
        padding: 0 $page-padding-nr-lg;
    }
    @media (max-width: $screen-md-max){
        padding: 0 $page-padding-nr-md;
    }
    @media (max-width: $screen-sm-max){
        padding: 0 $page-padding-nr-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: 0 $page-padding-nr-xs;
    }
    .references-list{
        position: relative;
        width: 100%;
        .slick-slide {
            margin-right: $spacer;
        }
        .slick-track,
        .slick-list{

            overflow: visible !important;
        }
        #references{
            width: 100%;
            .instance{
                max-width: calc(100vw - #{$page-padding-nr}*2);
                @media (max-width: $screen-xl-max){
                    max-width: calc(100vw - #{$page-padding-nr-xl}*2);
                }
                @media (max-width: $screen-lg-max){
                    max-width: calc(100vw - #{$page-padding-nr-lg}*2);
                }
                @media (max-width: $screen-md-max){
                    max-width: calc(100vw - #{$page-padding-nr-md}*2);
                }
                @media (max-width: $screen-sm-max){
                    max-width: calc(100vw - #{$page-padding-nr-sm}*2);
                }
                @media (max-width: $screen-xs-max){
                    max-width: calc(100vw - #{$page-padding-nr-xs}*2);
                }
                .sources{
                    display: none;
                }
                position: relative;
                display: block;
                width: auto;
                text-decoration: none !important;
                &:hover{
                    .media-holder{
                        .description{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                .media-holder{
                    max-width: 100%;
                    margin-bottom: $spacer*0.5;
                    position: relative;
                    border-radius: $border-radius;
                    overflow: hidden;
                    .image{
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                    
                    .description{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        padding: $spacer;
                        left: 0;
                        top: 0;
                        background-color: rgba(0,0,0,0.5);
                        color: white;
                        display: flex;
                        align-items: flex-end;
                        visibility: hidden;
                        opacity: 0;
                        transition: $def-trans;
                        p{ 
                            line-height: 1.2;
                            font-size: 0.9rem;
                        }
                    }
                }
                .content-holder{
                    max-width: 100%;
                    padding: 0 $border-radius;
                    .title{
                        font-family: $header-font;
                        color: $main-green;
                        font-size: 1.25rem;
                        max-width: 100%;
                        white-space: wrap;
                    }
                    .data{
                        display: flex;
                        align-items: center;
                        font-family: $header-font;
                        color: $text-color;
                        font-size: 0.95rem;
                        .city{
                        }
                        .sep{
                            padding: 0 10px;
                        }
                        .year{

                        }
                    }
                }
            }
        }
    }
}


#references-masonry{
    width: 100%;
    .grid-sizer{
        width: calc(25% - #{$spacer}*3/4);
        @media (max-width: $screen-lg-max){
            width: calc(33.3% - #{$spacer}*2/3);
        }
        @media (max-width: $screen-sm-max){
            width: calc(50% - #{$spacer}/2);
        }
        @media (max-width: $screen-xs-max){
            width: 100%;
        }
    }
    .gutter-sizer{
        width: $spacer;
        @media (max-width: $screen-xs-max){
            width: $spacer*0.5;
        }
    }
    .instance{
        margin-bottom: $spacer*2;
        width: calc(25% - #{$spacer}*3/4);
        @media (max-width: $screen-xl-max){
        }
        @media (max-width: $screen-lg-max){
            width: calc(33.3% - #{$spacer}*2/3);
        }
        @media (max-width: $screen-md-max){
        }
        @media (max-width: $screen-sm-max){
            width: calc(50% - #{$spacer}/2);
            margin-bottom: $spacer;
        }
        @media (max-width: $screen-xs-max){
            width: 100%;
            margin-bottom: $spacer*1.5;
        }
        .sources{
            display: none;
        }
        position: relative;
        display: block;
        text-decoration: none !important;
        &:hover{
            .media-holder{
                .description{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        .media-holder{
            max-width: 100%;
            margin-bottom: $spacer*0.5;
            position: relative;
            border-radius: $border-radius;
            overflow: hidden;
            @media (max-width: $screen-md-max){
                border-radius: $border-radius-md;
            }
            @media (max-width: $screen-sm-max){
                border-radius: $border-radius-sm;
            }
            @media (max-width: $screen-xs-max){
                border-radius: $border-radius-xs;
            }
            .image{
                img{
                    width: 100%;
                    display: block;
                }
            }
            
            .description{
                position: absolute;
                width: 100%;
                height: 100%;
                padding: $spacer;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,0.5);
                color: white;
                display: flex;
                align-items: flex-end;
                visibility: hidden;
                opacity: 0;
                transition: $def-trans;
            }
        }
        .content-holder{
            width: 100%;
            display: block;
            white-space: wrap;
            padding: 0 $border-radius;
            
            @media (max-width: $screen-md-max){
                padding: 0 $border-radius-md;
            }
            @media (max-width: $screen-sm-max){
                padding: 0 $border-radius-sm;
            }
            @media (max-width: $screen-xs-max){
                padding: 0 $border-radius-xs;
            }
            .title{
                font-family: $header-font;
                color: $main-green;
                font-size: 1.25rem;
                max-width: 100%;
                white-space: wrap;
                @media (max-width: $screen-md-max){
                    font-size: 1.15rem;
                    line-height: 1.15;
                }
            }
            .data{
                display: flex;
                align-items: center;
                font-family: $header-font;
                color: $text-color;
                font-size: 0.95rem;
                .city{
                }
                .sep{
                    padding: 0 10px;
                }
                .year{

                }
            }
        }
    }
}




.pswp__custom-caption {
    background-color: rgba(0,0,0,0.8);
    border-radius: 20px !important;
    font-size: 16px;
    color: #fff;
    width: calc(100% - #{$header-height}*2);
    max-width: 500px;
    padding: $spacer*1.5 $spacer*2;
    position: absolute;
    left: 50%;
    bottom: $spacer;
    transform: translateX(-50%);
    @media (max-width: $screen-xl-max){
        width: calc(100% - #{$header-height-xl}*2);
    }
    @media (max-width: $screen-lg-max){
        width: calc(100% - #{$header-height-lg}*2);
    }
    @media (max-width: $screen-md-max){
        width: calc(100% - #{$header-height-md}*2);
    }
    @media (max-width: $screen-sm-max){
        width: calc(100% - #{$header-height-sm}*2);
    }
    @media (max-width: $screen-xs-max){
        width: calc(100% - #{$header-height-xs}*2);
    }
    .caption-content{
        .project{
            margin-bottom: $spacer*0.5;
            .title{
                font-family: $header-font;
                color: $main-green;
                font-size: 1.5rem;
            }
            .description{
                p{
                    color: white !important;
                }
            }
            .data{
                display: flex;
                align-items: center;
                font-family: $header-font;
                color: $text-color;
                font-size: 0.9rem;
                margin-bottom: $spacer*0.25;
                color:  $light-green;
                .city{
                }
                .sep{
                    padding: 0 10px;
                }
                .year{

                }
            }
        }
        .image{
            .num{
                font-size: 0.8333rem;
                color: rgba(255,255,255,0.75);
            }
            .title{
                font-family: $header-font;
                
            }
            .description{

            }
        }
    }
  }

  .slicknav{
    display: inline-block;
    margin-bottom: $spacer*0.5;
    position: relative;
    transform: translateX(0);
    transition: $def-trans;
    z-index: 500;
    opacity: 0.5;
    cursor: default;
    &.active{
        cursor: pointer;
        opacity: 1;
        &:hover{
            svg{
                stroke: $main-green;
            }
        }
    }
    svg{
        width: 50px;
        height: 50px;
        stroke-width: 1px !important;
    }
    &.prev.active{
        &:hover{
            transform: translateX(-5px);
        }
    }
    &.next.active{
        &:hover{
            transform: translateX(5px);
        }
    }

    @media (min-width: $screen-lg-max){
        position: absolute;
        top: 115px;
        svg{
            width: 100px;
            height: 100px;
            stroke-width: 0.75px !important;
            stroke: $gray-700;
        }
        &.prev{
            left: calc(#{$page-padding-nr} - 100px - $spacer);
            @media (max-width: $screen-xl-max){
                left: calc(#{$page-padding-nr-xl} - 100px - $spacer);
            }
            @media (max-width: $screen-lg-max){
                left: calc(#{$page-padding-nr-lg} - 100px - $spacer);
            }
        }
        &.next{
            right: calc(#{$page-padding-nr} - 100px - $spacer);
            @media (max-width: $screen-xl-max){
                right: calc(#{$page-padding-nr-xl} - 100px - $spacer);
            }
            @media (max-width: $screen-lg-max){
                right: calc(#{$page-padding-nr-lg} - 100px - $spacer);
            }
        }
    }
  }

  