@mixin add-responsive-spacings-mixins($size, $min-width) {
    @media (min-width: $min-width) {
        @for $i from 0 through 10 {
            $spacing: calc(0.4rem * #{$i});
            .p-#{$size}-#{$i} { 
                padding: $spacing !important;
            }
            .px-#{$size}-#{$i} { 
                padding-left: $spacing !important;
                padding-right: $spacing !important;
            }
            .py-#{$size}-#{$i} { 
                padding-top: $spacing !important;
                padding-bottom: $spacing !important;
            }
            .pl-#{$size}-#{$i} { 
                padding-left: $spacing !important;
            }
            .pr-#{$size}-#{$i} { 
                padding-right: $spacing !important;
            }
            .pt-#{$size}-#{$i} { 
                padding-top: $spacing !important;
            }
            .pb-#{$size}-#{$i} { 
                padding-bottom: $spacing !important;
            }
            .m-#{$size}-#{$i} { 
                margin: $spacing !important;
            }
            .mx-#{$size}-#{$i} { 
                margin-left: $spacing !important;
                margin-right: $spacing !important;
            }
            .my-#{$size}-#{$i} { 
                margin-top: $spacing !important;
                margin-bottom: $spacing !important;
            }
            .ml-#{$size}-#{$i} { 
                margin-left: $spacing !important;
            }
            .mr-#{$size}-#{$i} { 
                margin-right: $spacing !important;
            }
            .mt-#{$size}-#{$i} { 
                margin-top: $spacing !important;
            }
            .mb-#{$size}-#{$i} { 
                margin-bottom: $spacing !important;
            }
        }
    }
}


@for $i from 0 through 10 {
    $spacing: calc(0.4rem * #{$i});
    .p-#{$i} { 
        padding: $spacing !important;
    }
    .px-#{$i} { 
        padding-left: $spacing !important;
        padding-right: $spacing !important;
    }
    .py-#{$i} { 
        padding-top: $spacing !important;
        padding-bottom: $spacing !important;
    }
    .pl-#{$i} { 
        padding-left: $spacing !important;
    }
    .pr-#{$i} { 
        padding-right: $spacing !important;
    }
    .pt-#{$i} { 
        padding-top: $spacing !important;
    }
    .pb-#{$i} { 
        padding-bottom: $spacing !important;
    }
    .m-#{$i} { 
        margin: $spacing !important;
    }
    .mx-#{$i} { 
        margin-left: $spacing !important;
        margin-right: $spacing !important;
    }
    .my-#{$i} { 
        margin-top: $spacing !important;
        margin-bottom: $spacing !important;
    }
    .ml-#{$i} { 
        margin-left: $spacing !important;
    }
    .mr-#{$i} { 
        margin-right: $spacing !important;
    }
    .mt-#{$i} { 
        margin-top: $spacing !important;
    }
    .mb-#{$i} { 
        margin-bottom: $spacing !important;
    }
}

@include add-responsive-spacings-mixins("xs", $screen-xs-min);
@include add-responsive-spacings-mixins("sm", $screen-sm-min);
@include add-responsive-spacings-mixins("md", $screen-md-min);
@include add-responsive-spacings-mixins("lg", $screen-lg-min);
@include add-responsive-spacings-mixins("xl", $screen-xl-min);
@include add-responsive-spacings-mixins("xxl", $screen-xxl-min);


@mixin add-responsive-size-mixins($size, $min-width) {
    @media (min-width: $min-width) {
        @for $i from 1 through 10 {
            $dimension: calc(10% * #{$i});
            .h-#{$size}-#{$i} { 
                height: $dimension !important;
            }
            .w-#{$size}-#{$i} { 
                width: $dimension !important;
            }
        }
    }
}

@for $i from 1 through 10 {
    $dimension: calc(10% * #{$i});
    .h-#{$i} { 
        height: $dimension !important;
    }
    .w-#{$i} { 
        width: $dimension !important;
    }
}

@include add-responsive-size-mixins("xs", $screen-xs-min);
@include add-responsive-size-mixins("sm", $screen-sm-min);
@include add-responsive-size-mixins("md", $screen-md-min);
@include add-responsive-size-mixins("lg", $screen-lg-min);
@include add-responsive-size-mixins("xl", $screen-xl-min);
@include add-responsive-size-mixins("xxl", $screen-xxl-min);

.block-mb{
    margin-bottom: $spacer*10 !important;
    
    @media (max-width: $screen-xl-max){
        margin-bottom: $spacer*9 !important;
    }
    @media (max-width: $screen-lg-max){
        margin-bottom: $spacer*8 !important;
    }
    @media (max-width: $screen-md-max){
        margin-bottom: $spacer*7 !important;
    }
    @media (max-width: $screen-sm-max){
        margin-bottom: $spacer*6 !important;
    }
    @media (max-width: $screen-xs-max){
        margin-bottom: $spacer*5 !important;
    }
}

.page-start{
    padding-top: calc(#{$header-biglogo-height} + $spacer*3) !important;
    @media (max-width: $screen-xl-max){
        padding-top: calc(#{$header-biglogo-height-xl} + $spacer*3) !important;
    }
    @media (max-width: $screen-lg-max){
        padding-top: calc(#{$header-biglogo-height-lg} + $spacer*3) !important;
    }
    @media (max-width: $screen-md-max){
        padding-top: calc(#{$header-biglogo-height-md} + $spacer*3) !important;
    }
    @media (max-width: $screen-sm-max){
        padding-top: calc(#{$header-biglogo-height-sm} + $spacer*3) !important;
    }
    @media (max-width: $screen-xs-max){
        padding-top: calc(#{$header-biglogo-height-xs} + $spacer*3) !important;
    }
    &.small-top-padding{
        padding-top: $spacer*5 !important;
    }
}