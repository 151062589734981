
$focus-color: $main-green;
$accent-color: $dark-green;
$preloader-color: $main-green;
$preloader-color-dark: $main-green;
$error-color: red;
$success-background: $main-green;

$topPadding: $spacer*1.25;
$sidePadding: 0; //set if round borders
$bottomPadding: $spacer*0.25;
$borderRadius: 0;
$border: none;
$borderBottom: 1px solid $gray-200;
$borderBottomFocus: 1px solid $focus-color;



#form-holder{
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 10px 23px 0px rgba(0,0,0,0.1);
    border: 1px solid $gray-200;
    background-color: white;
    //background-image: url(../images/green_bg.jpg);
    background-size: cover;
    //background-color: rgba(255,255,255,0.5);
}
#contact-form{
    width: 100%;
    padding: $spacer*3.5;
    position: relative;
    z-index: 1;
    &.small{
        padding: $spacer*2.5;
    }
    @media (max-width: $screen-md-max){
        padding: $spacer*2.5;
    }
    @media (max-width: $screen-sm-max){
        padding: $spacer*2;
    }
    .form-group-title{
        font-family: $main-font;
        font-weight: $main-font-bold;
        color: $dark-green;
        font-weight: bold;
        font-size: 1rem;
    }
    .form-element{
        position: relative;
        input,textarea{
            border: $border;
            border-bottom: $borderBottom;
            width: 100%;
            font-family: $main-font;
            font-weight: $main-font-regular;
            font-size: 0.83rem;
            padding: $topPadding $sidePadding $bottomPadding $sidePadding;
            border-radius: $borderRadius;
            z-index: 1;
            background: none;
            margin: 0;
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: none;
                border-bottom: $borderBottomFocus;
            }
        }
        .element-label{
            position: absolute;
            font-family: $main-font;
            font-weight: $main-font-regular;
            font-size: 0.9rem;
            left: $sidePadding;
            top: $topPadding;
            transition: $def-trans;
            color: $gray-800;
            z-index: -1;
        }
        &.active{
            .element-label{
                color: $gray-400;
                font-size: $spacer*0.75;
                line-height: $spacer*0.75;
                left: $sidePadding;
                top: calc((#{$topPadding} - #{$spacer*0.75}) / 2);
            }
        }
        &.checkbox{
            display: flex;
            justify-content: flex-start;
            input{
                width: auto;
                width: 0;
                opacity: 0;
            }
            label{
                display: flex;
                flex-wrap: nowrap;
                cursor: pointer;
                .text-wrapper{
                    font-size: 0.9rem;
                    font-family: $main-font;
                    font-weight: $main-font-regular;
                    p{
                        color: $gray-700;
                        font-size: 0.9rem;
                        font-family: $main-font;
                        font-weight: $main-font-regular;
                        margin: 0;
                    }
                    small{
                        color: $gray-700;
                        cursor: default;
                        a{
                            text-decoration: underline;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                        p{
                            color: $gray-700;
                            font-size: 0.8rem;
                            margin: 0;
                        }
                    }
                }
                &.flex-label{
                    display: flex;
                    align-items: flex-start;
                    .checkbox-vis{
                        transform: translateY(15px);
                    }
                }
            }
            .checkbox-vis{
                display: block;
                position: relative;
                min-width: 18px !important;
                min-height: 18px !important;
                width: 18px !important;
                height: 18px !important;
                border-radius: 3px;
                display: flex;
                align-items: center;
                margin-right: calc(#{$spacer} *0.5);
                margin-top: $spacer*0.05;
                border: 1px solid $gray-500;
                transition: $def-trans;
                transform: translateY(4px);
                i,svg{
                    margin: 0 auto;
                    font-size: 0.75rem;
                    color: rgba(255,255,255,0.85);
                    visibility: hidden;
                    opacity: 0;
                }
                &.focus{
                    border-color: $accent-color;
                }
                &.selected{
                    background-color: $accent-color;
                    border-color: $accent-color !important;
                    i,svg{
                        visibility: visible;
                        opacity: 1;
                    } 
                }
            }
        }
        .error-text{
            font-size: 0.8rem;
            color: $error-color;
            line-height: 0.8rem;
            margin: calc(#{$spacer} * 0.25) 0;
        }
        &:not(.checkbox){
            &.error{
                input,textarea{
                    color: $error-color;
                    border-color: $error-color !important;
                }
            }
        }
        &.checkbox{
            &.error{
                .checkbox-vis{
                    border-color: $error-color !important;
                }
            }
        }
        
    }
    button{
        font-size: 1rem !important;
    }
    .fixed-data{
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: $def-trans;
        &.visible{
            visibility: visible;
            opacity: 1;
            max-height: 1000px;
        }
        .form-element{
            position: relative;
            input,textarea{
                border-bottom: 1px solid $gray-200;
                cursor: default;
            }
            .element-label{
                display: none !important;
            }
            .cancel{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                color: rgba(100,100,100,1);
                transition: $def-trans;
                &:hover{
                    color: $error-color;
                }
            }
        }
    }
}


#form-busy {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    z-index: 100;
    display: none;
    .preloader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        visibility: visible;
        opacity: 1;
        transition: $def-trans;
    }

    .preloader_full-screen {
        height: 100%;
    }

    .circle {
        position: relative;
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }

    .circle_orange {
        background-color: $preloader-color;
        transform: translateX(13px);
        animation: animate-green 2s linear infinite;
    }

    .circle_dark_orange {
        background-color: $preloader-color-dark;
        transform: translateX(-13px);
        animation: animate-red 2s linear infinite;
    }
    @keyframes animate-green {
        0% {
            background-color: $preloader-color;
        }

        3% {
            background-color: $preloader-color;
            transform: translateX(13px);
        }

        9% {
            background-color: $preloader-color;
            transform: translateX(-20px);
        }

        14% {
            transform: translateX(2px);
        }

        18% {
            transform: translateX(-12px);
        }

        21% {
            transform: translateX(-3px);
        }

        23% {
            transform: translateX(-9px);
        }

        24% {
            transform: translateX(-7px);
        }

        30% {
            transform: translateX(-7px) scale(1);
            animation-timing-function: cubic-bezier(.6, 0, 1, 1);
        }

        43% {
            z-index: -1;
            transform: translateX(13px) scale(.6);
            animation-timing-function: cubic-bezier(0, 0, .4, 1);
        }

        56% {
            z-index: 1;
            transform: translateX(33px) scale(1);
            animation-timing-function: cubic-bezier(.6, 0, 1, 1);
        }

        69% {
            transform: translateX(13px) scale(1.4);
            animation-timing-function: cubic-bezier(0, 0, .4, 1);
        }

        82% {
            z-index: 1;
            background-color: $preloader-color;
            transform: translateX(-7px) scale(1);
            animation-timing-function: ease-in;
        }

        98% {
            background-color: $preloader-color;
            transform: translateX(13px);
        }

        100% {
            background-color: $preloader-color;
        }
    }
    @keyframes animate-red {
        0% {
            background-color: $preloader-color-dark;
        }

        3% {
            background-color: $preloader-color-dark;
            transform: translateX(-13px);
        }

        9% {
            background-color: $preloader-color-dark;
            transform: translateX(20px);
        }

        14% {
            transform: translateX(-2px);
        }

        18% {
            transform: translateX(12px);
        }

        21% {
            transform: translateX(3px);
        }

        23% {
            transform: translateX(9px);
        }

        24% {
            transform: translateX(7px);
        }

        30% {
            transform: translateX(7px) scale(1);
            animation-timing-function: cubic-bezier(.6, 0, 1, 1);
        }

        43% {
            transform: translateX(-13px) scale(1.4);
            animation-timing-function: cubic-bezier(0, 0, .4, 1);
        }

        56% {
            transform: translateX(-33px) scale(1);
            animation-timing-function: cubic-bezier(.6, 0, 1, 1);
        }

        69% {
            transform: translateX(-13px) scale(.6);
            animation-timing-function: cubic-bezier(0, 0, .4, 1);
        }

        82% {
            background-color: $preloader-color-dark;
            transform: translateX(7px) scale(1);
            animation-timing-function: ease-in;
        }

        98% {
            background-color: $preloader-color-dark;
            transform: translateX(-13px);
        }

        100% {
            background-color: $preloader-color-dark;
        }
    }
}

#message-sent{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $success-background;
    z-index: 0;
    padding: $spacer*3.5;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: $def-trans;
    &.isvis{
        visibility: visible;
        opacity: 1;
        z-index: 101;
    }
    @media (max-width: $screen-md-max){
        padding: $spacer*2.5;
    }
    @media (max-width: $screen-sm-max){
        padding: $spacer*2;
    }
    .content{
        width: 100%;
        text-align: center;
        .image{
            width: 80%;
            margin: 0 auto;
            margin-bottom: $spacer*2;
            max-width: 300px;
            img{
                width: 100%;
            }
        }
        .text{
            .h1{
                color: rgba(255,255,255,0.85);
            }
            p{
                font-size: 1.25rem;
                margin-bottom: $spacer*0.25;
                color: rgba(255,255,255,0.9);
            }
            small{
                margin: 0;
                font-size: $smallTextFontSize;
                line-height: 1;
                color: rgba(255,255,255,0.7);
            }
        }
    }
}



#file-upload{
    border: 1px solid $gray-300;
    background-color: $gray-100;
}
// #file-upload{
//     width: 100%;
//     height: 150px;;
//     border-radius: $border-radius;
//     background-color: $gray-100;
//     border: 3px solid $main-green;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     button{
//         border: none;
//         margin: 0;
//         padding: 0;
//         width: auto;
//         overflow: visible;
    
//         background: transparent;
    
//         /* inherit font & color from ancestor */
//         color: inherit;
//         font: inherit;
    
//         /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
//         line-height: normal;
    
//         /* Corrects font smoothing for webkit */
//         -webkit-font-smoothing: inherit;
//         -moz-osx-font-smoothing: inherit;
    
//         /* Corrects inability to style clickable `input` types in iOS */
//         -webkit-appearance: none;
//         background-color: $main-green;
//         color: white;
//         font-size: 0.8333rem;
//         padding: $spacer*0.5 $spacer*0.75;
//         border-radius: 5px;
//     }
// }