.main-nav-ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    li{
        margin-right: $spacer;
        &:last-child{
            margin-right: 0;
        }
        a{
            position: relative;
            display: block;
            font-size: 1rem;
            text-decoration: none;
            white-space: nowrap;
            font-family: $header-font;
            font-weight: $header-font-regular;
            transition: $def-trans;
        }
        &.active{
            a{
                font-weight: $header-font-bold;
                cursor: default;
            }
        }
        &:not(.active){
            a{
                &:hover{
                    transform: translateY(-2px);
                }
            }
        }
    }
}