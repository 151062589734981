#top-image{
    position: relative;
    width: calc(100% - #{$header-height} * 2); 
    margin: 0 $header-height;
    overflow: hidden;
    border-radius: $border-radius;
    min-height: calc(#{$header-biglogo-height} + 100px);
    @media (max-width: $screen-xl-max){
        width: calc(100% - #{$header-height-xl} * 2); 
        min-height: calc(#{$header-biglogo-height-xl} + 100px);
        margin: 0 $header-height-xl;
    }
    @media (max-width: $screen-lg-max){
        width: calc(100% - #{$header-height-lg} * 2); 
        min-height: calc(#{$header-biglogo-height-lg} + 100px);
        margin: 0 $header-height-lg;
    }
    @media (max-width: $screen-md-max){
        width: calc(100% - #{$header-height-md} * 2); 
        min-height: calc(#{$header-biglogo-height-md} + 100px);
        margin: 0 $header-height-md;
    }
    @media (max-width: $screen-sm-max){
        width: calc(100% - #{$header-height-sm} * 2); 
        min-height: calc(#{$header-biglogo-height-sm} + 100px);
        margin: 0 $header-height-sm;
    }
    @media (max-width: $screen-xs-max){
        width: calc(100% - #{$header-height-xs} * 2); 
        min-height: calc(#{$header-biglogo-height-xs} + 100px);
        margin: 0 $header-height-xs;
    }
    &:after{
        content: '';
        display: block;
        padding-bottom: 27.5%;
    }
    h1{
        margin-left: $header-height*-1;
        @media (max-width: $screen-xl-max){
            margin-left: $header-height-xl*-1;
        }
        @media (max-width: $screen-lg-max){
            margin-left: $header-height-lg*-1;
        }
        @media (max-width: $screen-md-max){
            margin-left: $header-height-md*-1;
        }
        @media (max-width: $screen-sm-max){
            margin-left: $header-height-sm*-1;
        }
        @media (max-width: $screen-xs-max){
            margin-left: $header-height-xs*-1;
        }
    }
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .content{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        h1{
            color: white;
        }
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0);
        z-index: 0;
    }
    &.bright{
        .overlay{
            background-color: rgba(0,0,0,0.25);
        }
    }
}