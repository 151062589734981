#jobs-snippets-container{
    .job-snippet{
        width: 100%;
        border-radius: $border-radius;
        border: 1px solid $light-green;
        box-shadow: 0px 10px 23px 0px rgba(0,0,0,0.1);
        border: 1px solid $gray-200;
        background-color: white;
        padding: $spacer*2;
        margin-bottom: $spacer*2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none !important;
        transition: $def-trans;
        &:hover{
            transform: translateY(-5px);
            box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
            border: 1px solid $main-green;
        }
        .top{
            margin-bottom: $spacer*3;
            .title{
                font-family: $header-font;
                font-size: 1.5rem;
                color: $dark-green;
                margin-bottom: $spacer*0.25;
            }
            .subtitle{
                font-family: $main-font;
                font-weight: $main-font-bold;
                font-weight: bold;
                font-size: 1rem;
                color: $main-green;
                margin-bottom: $spacer;
            }
            .description{
                font-size: 0.8333rem;
                font-weight: $main-font-regular;
            }
        }
        .bottom{
            ul{
                margin: 0;
                padding: 0;
                list-style-type: none;
                li{
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 0.8333rem;
                }
                li:not(:last-child){
                    margin-bottom: $spacer;
                }
            }
        }
    }
}


.job-options{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    li{
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
    }
    li:not(:last-child){
        margin-right: $spacer;
    }
}

.shortdescr{
    font-size: 0.9rem;
}

.job-description{
    padding: 0;
    margin: 0;
    li{
        font-size: 1.15rem;
        margin-bottom: $spacer*0.5;
    }
}