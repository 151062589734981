$spacer: 1rem;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$def-trans: all 200ms ease-out;
$long-trans: all 750ms ease-out;

$text-color: #404040;
$main-green: #9FAB26;
$dark-green: #21341D;
$light-green: #DEDFB6;

$accent-color: red;
$dark-accent-color: red;
$alert-color: red;
$screen-xxs-max: 449px;
$screen-xs-min: 450px;
$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-lg-max: 1199px;
$screen-xl-min: 1200px;
$screen-xl-max: 1499px;
$screen-xxl-min: 1500px;
$screen-xxxl-min: 2300px;

$main-font: "neue-haas-grotesk-text", sans-serif;
$header-font: 'ff-cocon-pro-condensed', sans-serif;

$main-font-regular: 400;
$main-font-bold: 700;
$header-font-regular: 400;
$header-font-bold: 400;

$header-height: 44px;
$header-height-xl: 40px;
$header-height-lg: 36px;
$header-height-md: 32px;
$header-height-sm: 28px;
$header-height-xs: 24px;

$header-height-sticky: 60px;
$header-height-sticky-xl: 58px;
$header-height-sticky-lg: 56px;
$header-height-sticky-md: 54px;
$header-height-sticky-sm: 52px;
$header-height-sticky-xs: 50px;

$header-biglogo-height: 230px;
$header-biglogo-height-xl: 200px;
$header-biglogo-height-lg: 180px;
$header-biglogo-height-md: 160px;
$header-biglogo-height-sm: 140px;
$header-biglogo-height-xs: 100px;

$header-biglogo-height-tiny: 150px;
$header-biglogo-height-tiny-xl: 140px;
$header-biglogo-height-tiny-lg: 130px;
$header-biglogo-height-tiny-md: 120px;
$header-biglogo-height-tiny-sm: 110px;
$header-biglogo-height-tiny-xs: 100px;


$page-padding: $spacer*5;
$page-padding-xl: $spacer*5;
$page-padding-lg: $spacer*4;
$page-padding-md: $spacer*3;
$page-padding-sm: $spacer*2;
$page-padding-xs: $spacer;

$page-padding-nr: $spacer*15;
$page-padding-nr-xl: $spacer*12;
$page-padding-nr-lg: $spacer*10;
$page-padding-nr-md: $spacer*5;
$page-padding-nr-sm: $spacer*4;
$page-padding-nr-xs: $header-height;

$border-radius-xs: 12px;
$border-radius-sm: 15px;
$border-radius-md: 18px;
$border-radius-lg: 20px;
$border-radius-xl: 20px;
$border-radius: 20px;

