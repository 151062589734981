* {
    box-sizing: border-box;
}

.oh{
    overflow: hidden;
}

.container-fluid{
    padding: 0 $page-padding;
    @media (max-width: $screen-xl-max){
        padding: 0 $page-padding-xl;
    }
    @media (max-width: $screen-lg-max){
        padding: 0 $page-padding-lg;
    }
    @media (max-width: $screen-md-max){
        padding: 0 $page-padding-md;
    }
    @media (max-width: $screen-sm-max){
        padding: 0 $page-padding-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: 0 $page-padding-xs;
    }
}

.container-fluid.nr{
    padding: 0 $page-padding*3.5;
    max-width: 1800px;
    @media (max-width: $screen-xl-max){
        padding: 0 $page-padding-xl*1.75;
    }
    @media (max-width: $screen-lg-max){
        padding: 0 $page-padding-lg;
    }
    @media (max-width: $screen-md-max){
        padding: 0 $page-padding-md*1.25;
    }
    @media (max-width: $screen-sm-max){
        padding: 0 $page-padding-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: 0 $page-padding-xs;
    }
}

.cc-window{
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    border-radius: 35px;
    a{
        text-decoration: underline;
    }
    .cc-compliance{
        a{
            border-radius: 35px;
            text-decoration: none;
            transition: $def-trans;
            border: 3px solid $main-green;
            color: $text-color;
            background: white;
            font-family: $header-font;
            font-weight: $header-font-bold;
            &:hover{
                border: 3px solid $main-green;
                background: $main-green;
                color: white;
            }
        }
    }
}

.form-control:focus{
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pswp{
    z-index: 15000;
}